import claims from '@/helpers/ClaimsHelper';
import StringHelper from '@/helpers/StringHelper';
import TypeHelper from '@/helpers/TypeHelper';
import ArrayHelper from '@/helpers/ArrayHelper';
import EnumUtility from '@/enums/EnumUtility';

export enum UserStatusAction {
  Activate,
  Inactivate,
  Delete
}

export enum RestApiScopes {
  None = 0x00,
  Reporting = 0x01
}

export abstract class RestApiScopesUtil {
  public static toDisplay(type: RestApiScopes): string {
    const getString = (t: RestApiScopes): string => {
      if (t === undefined) {
        t = 0;
      }

      switch (t) {
        default:
          return RestApiScopes[<number>t];
      }
    };

    return EnumUtility.flagsEnumToDisplay(type, RestApiScopes, getString);
  }
}

export abstract class UserHelper {
  public static get permissionsOwnRoleError(): string { return 'Permissions cannot be modified for a role you are assigned to.'; }

  public static get permissionsAdminUserError(): string { return 'Permissions cannot be modified for users in an administrator role.'; }

  public static get permissionsAdminRoleError(): string { return 'Permissions cannot be modified for administrator roles.'; }

  public static async updateUserStatus($api: any, $service: any, userId: string, action: UserStatusAction, onSuccess: () => void = null, onFail: () => void = null, showSuccessMessage: boolean = true) {
    if (!$api || !$service || StringHelper.isNullOrWhiteSpace(userId) || TypeHelper.isNull(action)) {
      return;
    }
    // Inactive and deleted both use Inactive status.
    const userStatus = action === UserStatusAction.Activate ? 'Active' : 'Inactive';

    const msg = action === UserStatusAction.Delete
      ? 'User was successfully deleted'
      : 'User status successfully updated';

    const userListPayload = {
      id: userId,
      status: userStatus
    };

    const userProfilePayload = {
      userId,
      status: userStatus
    };

    // User data API structure requires 2 API calls to update the identity authority status and profile status so even if the secondary...
    // ...call fails it is still determined a success so we call onSuccess regardless and suppress snack errors for the settings update.
    const response = await $api.put(`${$service.UserList_Api_Url}User/status`, userListPayload, showSuccessMessage ? msg : null);
    if (!response || response.status !== 200) {
      if (onFail) {
        onFail();
      }
      return;
    }
    if (onSuccess) {
      onSuccess();
    }

    await $api.put(`${$service.UserProfile_Api_Url}Settings/UpdateStatus`, userProfilePayload, null, 'suppress');
  }

  public static async notifyPermissionsManager($api: any, $service: any, userId: string = null){
    if (!$api || !$service) {
     return;
    }
    const userParam = StringHelper.isNullOrEmpty(userId) ? '' : `?userId=${userId}`;
    $api.get(`${$service.SearchController_Api_Url}Permission/RefreshPermissions${userParam}`, null, 'suppress');
  }

  public static async getUserAssignedRoles($api: any, $service: any, $store: any, userId: string = null) : Promise<string[]> {
    let ret: string[] = [];
    if (TypeHelper.isNull($api) || TypeHelper.isNull($service) || TypeHelper.isNull($store)) {
      return ret;
    }

    const userIdToLoad = StringHelper.isNullOrEmpty(userId) ? $store.getters['session/userId'] : userId;
    if (StringHelper.isNullOrEmpty(userIdToLoad)) {
      return ret;
    }

    const response = await $api.get(`${$service.UserList_Api_Url}User/rolesByUserId/${userIdToLoad}`, null, 'suppress');
    if (response && ArrayHelper.isArraySet(response.data)) {
      ret = response.data.map(d => d.roleId);
    }
    return ret;
  }

  public static async getAdminRoleIds($api: any, $service: any): Promise<string[]> {
    if (TypeHelper.isNull($api) || TypeHelper.isNull($service)) {
      return [];
    }

    const response = await $api.get(`${$service.UserList_Api_Url}Role/adminRoleIds`, null, 'suppress');
    return response && ArrayHelper.isArraySet(response.data) ? response.data : [];
  }
}

export class RoleClaimArea {
  public id: string;

  public areaTitle: string = '';

  public hint: string;

  public viewSelected: boolean = false;

  private manageSelectedInternal: boolean = false;

  private viewClaims: string[] = [];

  private manageClaims: string[] = [];

  constructor(areaTitle: string, viewClaims: string[], manageClaims: string[], hint: string) {
    this.id = TypeHelper.getGuid(); // internal use only so each grid row has a unique id
    this.areaTitle = areaTitle;
    this.hint = hint;
    this.viewClaims = ArrayHelper.isArraySet(viewClaims) ? viewClaims : [];
    this.manageClaims = ArrayHelper.isArraySet(manageClaims) ? manageClaims : [];
  }

  public validateAllClaims(validClaims: string[]) {
    if (!ArrayHelper.isArraySet(validClaims)) {
      // validClaims will only be empty if the all-permissions API fails.  In that case we assume what is coded in the UI is valid.
      return;
    }
    // valid claims are those returned by the IA all-permissions API.  Attempting to save a role with a claim not in this list ...
    // ... will be rejected by the API.  To ensure we only save valid claims, all hard coded claims in both lists will need to ...
    // ... have invalid claims removed.
    this.viewClaims = this.viewClaims.filter(c => validClaims.some(v => v === c));
    this.manageClaims = this.manageClaims.filter(c => validClaims.some(v => v === c));
  }

  public get manageSelected(): boolean { return this.manageSelectedInternal; }

  public set manageSelected(val: boolean) {
    this.manageSelectedInternal = val;
    // View claims are required when manage is selected. View col checkbox will be disabled by the UI
    if (this.manageSelectedInternal && this.hasViewClaims) {
      this.viewSelected = true;
    }
  }

  public get disableViewSelection(): boolean { return this.manageSelected; }

  public get hasViewClaims(): boolean { return ArrayHelper.isArraySet(this.viewClaims); }

  public get hasManageClaims(): boolean { return ArrayHelper.isArraySet(this.manageClaims); }

  // Used to hide an area from the UI if there are no claims. E.g. this handles when, for example, compliance is not enabled. In ...
  // ... this case no compliance claims are included in all-permissions validClaims resulting in finder area being hidden
  public get isValid(): boolean { return this.hasViewClaims || this.hasManageClaims; }

  // For dev analysis to view all claims after they have been validated and invalid claims removed
  // public get hint(): string { return `View (${this.viewClaims.length}): ${this.viewClaims.join(', ')}<br>Manage (${this.manageClaims.length}): ${this.manageClaims.join(', ')}`; }

  public getAllClaims(verifySelected: boolean = false): string[] {
    if (verifySelected) {
      let all: string[] = [];
      if (this.viewSelected) {
        all = this.viewClaims;
      }
      if (this.manageSelected) {
        all = all.concat(this.manageClaims);
      }
      return all;
    }
    return this.viewClaims.concat(this.manageClaims);
  }

  public processClaimsForEdit(claims: string[]) {
    if (!ArrayHelper.isArraySet(claims)) {
      return;
    }
    // Process manage claims.
    if (this.hasManageClaims) {
      this.manageSelected = !this.manageClaims.some(m => TypeHelper.isNull(claims.find(c => StringHelper.stringsEqualIgnoreCase(c, m))));
    }

    if (this.manageSelected) {
      // When manage is selected then view is automatically selected and disabled so in this case we do not have to check the view claims below
      return;
    }

    if (this.hasViewClaims) {
      this.viewSelected = !this.viewClaims.some(m => TypeHelper.isNull(claims.find(c => StringHelper.stringsEqualIgnoreCase(c, m))));
    }
  }
}

export class RoleClaimAreas {
  // No custom role should ever have these claims
  private readonly restrictedClaims: string[] = [
    claims.Installation,
    claims.SysRoleAdministrator,
    claims.Admin
  ];

  private readonly adminSettingsArea = new RoleClaimArea('Manage Administrative Settings',
    [],
    [claims.AccessSystemAdminSettings],
    'Grants permissions to view and manage Application Settings, Global Data Types, Global Classifications, and Audit Log.');

  // NOTE: claims.UserLIST, claims.UserREAD, claims.RoleREAD are left out of this area and all areas on purpose. E.g. these 3 claims are used ...
  // ... by the back end in multiple areas for user permissions syncing (RBAC). Not including them in any 'area' on the UI ensures all ...
  // ... custom roles receive these 3 claims.  None of these claims allow access to user management alone so this area still grants ...
  // ... the custom role access for managing users and roles on the UI
  private readonly usersRolesArea = new RoleClaimArea('Manage Users and Roles', [],
    [claims.ImportUserREAD, claims.ImportUserWRITE, claims.ResetPwdEmailREAD, claims.ResetPwdEmailWRITE, claims.RoleDELETE,
      claims.RoleWRITE, claims.UserDELETE, claims.UserWRITE, claims.UserProfileREAD, claims.UserProfileWRITE],
    'Grants permissions to view and manage system users and roles.<br><br><i>Only administrative users can manage RBAC permissions for users and roles.</i>');

  private readonly policiesArea = new RoleClaimArea('Agent Policies / Installation',
    [claims.AssignPolicyREAD, claims.PolicyConfigREAD, claims.AgentConfigurationREAD],
    [claims.AssignPolicyWRITE, claims.PolicyConfigWRITE, claims.AgentConfigurationWRITE, claims.AgentConfigurationDELETE],
    'Grants permissions to view / manage agent policies and agent installation configurations.');

  private readonly createTagsTargetsArea = new RoleClaimArea('Create Tags and Targets',
    [],
    [claims.AddTag, claims.AddNewEndpoint],
    'Grants permissions to create tags and targets.<br><br><i>RBAC permissions are used to control what tags and targets a user in this role can view, modify, or delete. By default users are allowed to view and manage any tag/target they create.</i>');

  private readonly finderArea = new RoleClaimArea('Sensitive Data Finder',
    [claims.ComplianceDashboard, claims.ComplianceAllRequests, claims.ComplianceViewAllReports, claims.ComplianceViewRequestDetails, claims.ComplianceViewSubjectResults],
    [claims.ComplianceFileContentReviewChangeMatchStatus, claims.CompliancCompleteSubjectRequest, claims.ComplianceNewSubjectRequest,
      claims.ComplianceCreateReport, claims.ComplianceDeleteSubjectRequest, claims.ComplianceEditSubjectRequest, claims.ComplianceResultsComments,
      claims.ComplianceReviewFileContent, claims.ComplianceStartMatching, claims.ComplianceDuplicateRequest, claims.ComplianceExportReport, claims.ComplianceExportResults],
    'Grants permissions to view / manage Sensitive Data Finder settings, requests, results, and reports.');

  private readonly finderErasureArea = new RoleClaimArea('Sensitive Data Finder (Erasure Checklist)',
    [claims.ComplianceErasureChecklist],
    [claims.ComplianceErasureEdit],
    'Grants permissions to view / manage Sensitive Data Finder erasure requests.');

  private readonly sdwArea = new RoleClaimArea('Sensitive Data Watchers',
    [claims.DefinitionPlaybookREAD, claims.IncidentArchiveREAD, claims.IncidentDefinitionREAD, claims.IncidentDefinitionScriptREAD, claims.IncidentDeploymentREAD,
      claims.IncidentREAD, claims.UpdateDefinitionStatusREAD, claims.QueryREAD, claims.QueryLIST, claims.ActivityWatcher, claims.DashboardREAD, claims.KSQLREAD,
      claims.PolicyConfigREAD],
    [claims.DefinitionPlaybookWRITE, claims.IncidentArchiveWRITE, claims.IncidentDefinitionWRITE, claims.IncidentDefinitionScriptWRITE, claims.IncidentDeploymentWRITE,
      claims.IncidentWRITE, claims.UpdateDefinitionStatusWRITE, claims.QueryWRITE, claims.KSQLWRITE, claims.PolicyConfigWRITE],
    'Grants permissions to view / manage Sensitive Data Watchers.');
  // Add this to the end of the text above if/when data watchers feature is completed. <br><br><i>The scan results view/manage permission is required to allow this role access for viewing or managing Sensitive Data Watcher scan results.</i>

  private readonly createScansArea = new RoleClaimArea('Create Scans',
    [],
    [claims.ConfigureNewScan],
    'Grants permissions to create sensitive data and discovery scans.<br><br><i>RBAC permissions are used to control the scans a user in this role can view, modify, or delete. By default users are allowed to view and manage any scan they create.</i>');

  private readonly scanResultsArea = new RoleClaimArea('Scan Results',
    [claims.ScanResultsRead],
    // Note: claims.RunScan is used in the Scans area as well.  It is required by the task scheduler API, RunPlaybookResult, call via the playbook executor.
    [claims.PlaybookExecutor, claims.Remediation, claims.ScanResultsWrite, claims.RunScan],
    'Grants permissions to view / manage sensitive data and discovery scan results.');

  private readonly scriptRepoArea = new RoleClaimArea('Script Repository',
    [claims.CustomScriptREAD, claims.ExportScript],
    [claims.CustomScriptWRITE, claims.DeleteScript, claims.EnableDisableScript],
    'Grants permissions to view / manage the custom script repository.');

  private readonly createPlaybooksArea = new RoleClaimArea('Create Playbooks',
    [],
    // playbooks have a node for custom scripts so the script read access is required
    [claims.CreatePlaybook, claims.CustomScriptREAD],
    'Grants permissions to create playbooks. Creating playbooks requires read-only access to the custom script repository. The manage option will grant this access when the role is created.<br><br><i>RBAC permissions are used to control the playbooks a user in this role can view, modify, or delete. By default users are allowed to view and manage any playbook they create.</i>');

  private readonly createReportsArea = new RoleClaimArea('Create Custom Reports',
    [],
    [claims.CreateCustomReport],
    'Grants permissions to create custom reports.<br><br><i>RBAC permissions are used to control the custom reports a user in this role can view, modify, or delete. By default users are allowed to view and manage any report they create.</i>');

  private readonly restApiArea = new RoleClaimArea('REST API',
    [],
    [claims.AccessRestApi],
    'Grants access to Spirion REST APIs for Custom Reporting.');

  // This will hold all valid claims that are not exposed on the UI. E.g. all roles should get these claims as from a UI perspective ...
  // ... we do not know if any are used by the APIs and excluding them could have unintended consequences.
  private claimsForAllRoles: string[] = [];

  public areas: RoleClaimArea[] = [];

  public async initialize($api: any, $service: any, editRoleId: string = null) : Promise<boolean> {
    let validClaims: string[] = [];

    // Pull all available claims from the API.  This is required to ensure all roles receive claims not exposed on the UI. E.g. claims coded into the read only areas above.
    const response = await $api.get(`${$service.UserList_Api_Url}Role/all-permissions`, null, 'The required configuration for creating a new role failed to load. Please try again.');
    if (response && response.status === 200 && response.data) {
      validClaims = response.data.map(d => d.claimValue);
    }
    else {
      return false;
    }

    const allAreas: RoleClaimArea[] = [
      this.adminSettingsArea,
      this.usersRolesArea,
      this.createTagsTargetsArea,
      this.createScansArea,
      this.createPlaybooksArea,
      this.createReportsArea,
      this.policiesArea,
      this.scanResultsArea,
      this.finderArea,
      this.finderErasureArea,
      this.sdwArea,
      this.scriptRepoArea,
      this.restApiArea
    ];

    // ensure validClaims has no restricted claims
    validClaims = validClaims.filter(v => !this.restrictedClaims.some(r => r === v));
    this.claimsForAllRoles = validClaims;

    allAreas.forEach(area => {
      // validate claims in all areas. e.g. remove claims coded into areas that are not in valid claims and therefore not valid per all-permissions API
      area.validateAllClaims(validClaims);
      if (area.isValid) {
        // filter all claims linked to UI areas out of claimsForAllRoles. This results in the array holding all claims ...
        // ... not exposed on the UI to ensure they are assigned to all new roles created.
        const areaClaims = area.getAllClaims();
        this.claimsForAllRoles = this.claimsForAllRoles.filter(cr => !areaClaims.some(ac => ac === cr));
      }
    });

    this.areas = allAreas.filter(a => a.isValid);

    if (!StringHelper.isNullOrWhiteSpace(editRoleId)) {
      const claimsResponse = await $api.get(`${$service.UserList_Api_Url}Role/claimsByRoleId/${editRoleId}`, null, 'The required configuration for modifying the role failed to load. Please try again.');
      if (!claimsResponse || claimsResponse.status !== 200) {
        return false;
      }
      if (ArrayHelper.isArraySet(claimsResponse.data)) { //e.g. the role has claims to process
        const roleClaims: string[] = (claimsResponse.data as any[]).map(d => d.claimValue).filter(v => !StringHelper.isNullOrEmpty(v));
        this.areas.forEach(area => area.processClaimsForEdit(roleClaims));
      }
    }
    return true;
  }

  public finalizeClaimsForSave() {
    let userSelectedClaims: string[] = [];
    (this.areas as RoleClaimArea[]).forEach(area => {
      userSelectedClaims = userSelectedClaims.concat(area.getAllClaims(true));
    });

    if (!ArrayHelper.isArraySet(userSelectedClaims)) {
      return this.claimsForAllRoles;
    }
    // Combine claims for all users and user selected claims then clean duplicates. E.g. Some areas duplicate claims for proper functionality.
    const fullSet = this.claimsForAllRoles.concat(userSelectedClaims);
    // Fast way to clear duplicates is to add all items to an object. The keys of the object will only have unique claims
    const claimsDictionary = {};
    fullSet.forEach(c => claimsDictionary[c] = c);
    return Object.keys(claimsDictionary);
  }
}
