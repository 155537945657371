import TypeHelper from '@/helpers/TypeHelper';
import { IdentityTypeInternal } from '@/helpers/IdentityTypeHelper';
import StringHelper from '@/helpers/StringHelper';
import ArrayHelper from '@/helpers/ArrayHelper';
import { calculateMinutesFromNow } from '@/helpers/DateHelper';
import { GridColumn, DateColumn } from '@/components/utility/GridUtilities';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import EnumUtility from '@/enums/EnumUtility';
import {
  EndpointStatus, AgentStatusUtil, AgentStatus, EnterpriseAgentType
} from '@/middleware/api/enums';
import { TagEditPermissions } from './PermissionsHelper';

dayjs.extend(utc);

export abstract class SearchInputCache {
  // Search Map is used in AlpineSearchbar.vue for caching searches.
  public static SearchMap = {};

  // Specific search bar input ids are used only for pages where a search value needs to be set before navigating to the page
  public static get scanListSearchInputId(): string { return 'ScanListSearchBarInput'; }

  public static get playbookListSearchInputId(): string { return 'PlaybookListSearchBarInput'; }

  // used for syncing the search text between scan results by location and scan results by match pages
  public static get scanResultsSearchInputId(): string { return 'ScanResultsSearchBarInput'; }

  public static get watcherResultsSearchInputId(): string { return 'WatcherResultsSearchBarInput'; }

  public static setSearchValue(inputId: string, value: string = '') {
    if (!StringHelper.isNullOrEmpty(inputId)) {
      SearchInputCache.SearchMap[inputId] = value || '';
    }
  }
}

export const getAgentIcon = (agentType: EnterpriseAgentType): string => {
  if (TypeHelper.isNull(agentType) || agentType !== EnterpriseAgentType.Agent) {
    // default to primary icon for everything except Agent type
    return '$alpine-agent';
  }
  return '$alpine-cast';
};

export abstract class AgentsHelper {
  public static get defaultPolicyName(): string { return 'Default Policy'; }

  public static async loadAgentsEndpointsNodesList($service: any, $api: any, payload: any): Promise<any> {
    if (TypeHelper.isNull($service) || TypeHelper.isNull($api) || TypeHelper.isNull(payload)) {
      return null;
    }
    const url = `${$service.SearchController_Api_Url}DiscoveryScan/GetAgentsEndpointsNodesList`;
    const response = await $api.post(url, payload);
    if (!TypeHelper.isNull(response) && !TypeHelper.isNull(response.data)) {
      // The API should not be returning tags but it is so we must filter here
      const hasData = ArrayHelper.isArraySet(response.data.data);
      const dataLengthWithTags = hasData ? response.data.data.length : 0;
      response.data.data = hasData ? response.data.data.filter(d => !d.isTag) : [];
      const dataLengthNoTags = response.data.data.length;
      // Must adjust the total or the grid count will be off and can break server paging
      response.data.total -= (dataLengthWithTags - dataLengthNoTags);

      // set default policy name for all agents (e.g. isRegister = true) with no policy assigned. When no policy is assigned the services will ...
      // ... use the default policy so we want to show that in the UI for all applicable agents.
      // Last heartbeat fields are returned as dates from the API so we convert them here as all UI that displays it uses the string values
      if (ArrayHelper.isArraySet(response.data.data)) {
        (response.data.data as any[]).forEach((e: any) => {
          if (e.isRegister && StringHelper.isNullOrEmpty(e.policyName)) {
            e.policyName = AgentsHelper.defaultPolicyName;
          }

          // for greyhound agents we override the status to offline if there is a last heartbeat over 60 minutes out. for sdm agents this is done on the server
          if (e.agentType === EnterpriseAgentType.Agent && !TypeHelper.isNull(e.sdmLastHeartbeat)) {
            const minutes = calculateMinutesFromNow(e.sdmLastHeartbeat);
            if (!TypeHelper.isNull(minutes) && minutes > 60) {
              e.status = AgentStatus.Offline;
            }
          }

          e.lastHeartbeat = this.getLastHeartbeatDisplay(e.lastHeartbeat);
          e.sdmLastHeartbeat = this.getLastHeartbeatDisplay(e.sdmLastHeartbeat);
        });
      }
    }
    return response;
  }

  public static buildGridHeaders(isAgentsGrid = false, includeSdv3TargetRisk: boolean = false): GridColumn[] {
    // API only supports sorting for the agent/target and current user columns
    // Commented watcher columns are for reference only. Watcher data shows via column templates in the grid component of the agent management and asset/target management pages
    const ret = [
      new GridColumn(isAgentsGrid ? 'Agent' : 'Target', 'endpointName', null, true, '250px'),
      //new GridColumn('Current User', 'userName', null, true, '150px'),
      new GridColumn('Status', 'sdmStatus', null, false, '150px'),
      new DateColumn('Last Heartbeat', 'sdmLastHeartbeat', false, '175px', false),
      //new GridColumn('Watcher Status', 'status', null, false, '125px'),
      //new DateColumn('Watcher Last Heartbeat', 'lastHeartbeat', false, '175px', false),
      new GridColumn('Policy', 'policyName', null, false, '150px'),
      //new GridColumn('Activity Watcher Policy', 'monPolicyName', null, false, '200px'),
      new GridColumn('Version', 'agentVersion', null, false, '100px')
    ];

    if (includeSdv3TargetRisk) {
      ret.push(new GridColumn('SDV3 Risk', 'sdv3Risk', null, false, '150px', false));
    }
    ret.push(new GridColumn('', 'more', 'right', false, '50px'));
    return ret;
  }

  private static getLastHeartbeatDisplay(d: any): string {
    const na = 'N/A';
    if (TypeHelper.isNull(d)) {
      return na;
    }
    const minutes = calculateMinutesFromNow(d);
    if (TypeHelper.isNull(minutes)) {
      return na;
    }

    if (minutes < 60) {
      return `${minutes} Minute(s)`;
    }
    return DateColumn.toDisplayString(d) || na;
  }

  // common methods used on agent management and target management pages for status display and colors
  public static getSdmAgentStatusLabel(status: number): string {
    return EndpointStatus[status] || 'N/A';
  }

  public static getSdmAgentStatusColor(status: number): string {
    if (status === EndpointStatus.Scanning || status === EndpointStatus.Monitoring) {
      return 'success';
    }
    if (status === EndpointStatus.Idle) {
      return 'warning';
    }
    if (status === EndpointStatus.Offline) {
      return 'error';
    }
    return 'grey';
  }

  public static getAgentStatusLabel(status: number): string {
    return AgentStatusUtil.toDisplay(status);
  }

  public static getAgentStatusColor(status: number): string {
    // This is a flags enum that scan have multiple status'. If error, offline is one of them, E.g. Scanning, Error, then show as red
    if (EnumUtility.hasFlag(status, AgentStatus.Error) || EnumUtility.hasFlag(status, AgentStatus.Offline)) {
      return 'error';
    }
    if (EnumUtility.hasFlag(status, AgentStatus.Discovering) || EnumUtility.hasFlag(status, AgentStatus.Searching)) {
      return 'success';
    }
    if (EnumUtility.hasFlag(status, AgentStatus.Idle)) {
      return 'warning';
    }
    return 'grey';
  }
}

export interface IEnumDisplayPosition {
  value: number;
  positionBefore: number;
}

export enum MaskType {
  Nothing = 0,
  LeaveLastFour = 1,
  All = 2
}

export class IPAddress {
  constructor(public octet1: number, public octet2: number, public octet3: number, public octet4: number) {
    this.octet1 = octet1;
    this.octet2 = octet2;
    this.octet3 = octet3;
    this.octet4 = octet4;
  }

  public static parse(address: string): IPAddress {
    /*
    Processing below is based on what IPAddress.TryParse returns.
    1. Must be at least 1 part and each part must be a valid number. 127.1 is valid where 127. and 127.t are not.
    2. If parts != 4 then last part is octet 4 and zeros are inserted to fill the empty octets
      127     = 0.0.0.127
      127.1   = 127.0.0.1
      127.1.2 = 127.1.0.2
  */
    if (!address || !address.length) {
      return null;
    }
    let parts = address.split('.').map((part: string) => {
      const octet = parseInt(part, 10);
      return octet === undefined || octet === null || isNaN(octet) || octet < 0 || octet > 255 ? null : octet;
    });
    // are any parts non-numeric
    if (parts.some((part: number) => part === null)) {
      return null;
    }
    // ensure 4 octets
    switch (parts.length) {
      case 1:
        parts = [0, 0, 0, parts[0]];
        break;
      case 2:
        parts = [parts[0], 0, 0, parts[1]];
        break;
      case 3:
        parts = [parts[0], parts[1], 0, parts[2]];
        break;
      default:
        break;
    }
    return new IPAddress(parts[0], parts[1], parts[2], parts[3]);
  }

  public getAddressBytes(): number[] {
    return [this.octet1, this.octet2, this.octet3, this.octet4];
  }
}
export class Pair<TFirst, TSecond>
{
  public first: TFirst;

  public second: TSecond;

  constructor(first: TFirst, second: TSecond) {
    this.first = first;
    this.second = second;
  }
}

export class NameValuePair<T>
{
  constructor(public name: string = null, public value: T = null) {
    this.name = name;
    this.value = value;
  }
}

export class NameStringValuePair extends NameValuePair<string>
{
  // eslint-disable-next-line
  constructor(name?: string, value?: string) {
    super(name, value);
  }
}

export class NameIntValuePair extends NameValuePair<number>
{
  // eslint-disable-next-line
  constructor(name?: string, value?: number) {
    super(name, value);
  }
}

export abstract class UIHelper {
  public static createActionNotification(actionLabel: string, success: boolean): string {
    return success
      ? `Congratulations! You have successfully completed ${actionLabel}`
      : `Failed to Complete ${actionLabel}. Please try again.`;
  }

  public static createCustomNotification(message: string, success: boolean): string {
    return success
      ? `${message}`
      : 'Failed to complete your request. Please try again.';
  }

  public static calculateDialogMaxWidth(defaultWidth: number): number {
    let maxWidth = window.innerWidth - 50;
    if (TypeHelper.isNull(maxWidth) || maxWidth < defaultWidth) {
      maxWidth = defaultWidth;
    }
    return maxWidth;
  }

  public static isUtcDate(date: Date): boolean {
    const d = dayjs(date);
    return !TypeHelper.isNull(d) && (d.isUTC() || (<any>d)._tzm === 0);
  }

  public static convertDate(value: Date): any {
    if (TypeHelper.isNull(value)) {
      return null;
    }
    const d = dayjs(value);
    if (TypeHelper.isNull(d)) {
      return value; // if dayjs did not init above then nothing below will work. just return value
    }
    let ret: any = new Date(value.toString()); // must create new date or setMinutes is undefined.
    const offset = ret.getTimezoneOffset();
    if (d.isUTC() || (<any>d)._tzm === 0) {
      ret.setMinutes(ret.getMinutes() + offset);
      ret = dayjs(ret).local();
    }
    else {
      ret.setMinutes(ret.getMinutes() - offset);
      ret = dayjs(ret).utc();
    }

    return ret.format();
  }

  public static formatDate(value: Date, utc: boolean): any {
    if (TypeHelper.isNull(value)) {
      return null;
    }

    return utc ? UIHelper.convertDate(value) : dayjs(value).local().format();
  }

  public static createDateFromUtcString(value: string): Date {
    if (TypeHelper.isNull(value)) {
      return null;
    }
    // This will create a date from a UTC string formatted as '2021-12-08T02:10:00Z' without adjusting the time to local browser time zone
    return new Date(dayjs(value).utc().format('MM/DD/YYYY HH:mm'));
  }

  public static toVolumeDisplay(val: number): string {
    if (TypeHelper.isNull(val) || val <= 0) {
      val = 0;
    }
    if (val < 1000) {
      return `${val} bytes`;
    }
    const decimalPlaces: number = 2;
    let adjusted = val / 1000;
    if (adjusted < 1000) {
      return `${adjusted.toFixed(decimalPlaces)} KB`;
    }
    adjusted /= 1000;
    if (adjusted < 1000) {
      return `${adjusted.toFixed(decimalPlaces)} MB`;
    }
    adjusted /= 1000;
    if (adjusted < 1000) {
      return `${adjusted.toFixed(decimalPlaces)} GB`;
    }
    adjusted /= 1000;
    if (adjusted < 1000) {
      return `${adjusted.toFixed(decimalPlaces)} TB`;
    }
    return `${(adjusted / 1000).toFixed(decimalPlaces)} PB`;
  }
}

export class NameInfo {
  public type: IdentityTypeInternal;

  public title: string;

  public maskType: MaskType;

  public typeId: number;

  public ids: number[];

  public hidden: boolean;

  public isAnyFind: boolean;

  public isOnlyFind: boolean;

  public code: string;

  public id: string;

  public isCustomType: boolean;
}

export class NameInfoCollection extends Array<NameInfo>
{
  constructor(data: any) {
    if (TypeHelper.isNull(data) || TypeHelper.isArray(data)) {
      let items: NameInfo[] = data;
      if (!items || !items.length) {
        items = [];
      }
      super(items.length);
      const that = this;
      items.forEach((i: NameInfo) => { that.push(i); });
    }
    else {
      super(data);
    }
    // this is needed, otherwise methods won't be called. E.g. in BaseResultsGridController.loadData, getTypeIdToTypeMap() won't be defined
    (<any>Object).setPrototypeOf(this, NameInfoCollection.prototype); // required when extending from array
  }

  public findTypeId(type: IdentityTypeInternal): number {
    return this.findByType(type).typeId;
  }

  public findByType(type: IdentityTypeInternal): NameInfo {
    return this.filter((i: NameInfo) => !i.isCustomType && i.type === type)[0];
  }

  public getTypeIdToTypeMap(): {} {
    return this.buildTypeIdMap((info: NameInfo) => info.type,
      (info: NameInfo) => !info.isCustomType);
  }

  public getTypeIdToMaskTypeMap(): {} {
    return this.buildTypeIdMap((info: NameInfo) => info.maskType,
      (info: NameInfo) => !info.isCustomType);
  }

  public toPairs(): NameIntValuePair[] {
    if (!this.length) {
      return [];
    }
    return this.map((info: NameInfo) => new NameIntValuePair(info.title, info.typeId));
  }

  private buildTypeIdMap(getDictionaryValue: (info: NameInfo) => any,
    canMap: (info: NameInfo) => boolean = () => true): {} {
    if (!this.length) {
      return {};
    }
    const ret = {};
    this.forEach((info: NameInfo) => {
      if (canMap(info)) {
        ret[info.typeId] = getDictionaryValue(info);
      }
    });
    return ret;
  }
}

export class TimeSpan {
  public days: number = 0;

  public hours: number = 0;

  public minutes: number = 0;

  public seconds: number = 0;

  public static fromString(span: string): TimeSpan {
    const ret = new TimeSpan(0, 0, 0, 0);
    if (StringHelper.isNullOrWhiteSpace(span)) {
      return ret;
    }
    const partsWithDays = span.split('.');
    let hoursMinutesSecondsIndex = 0;
    // spans can also have milliseconds (E.g. days.hours:minutes:seconds.ms). If first section after split contains ":" then span only has hours:minutes:seconds and milliseconds are ignored
    if (partsWithDays.length > 1 && partsWithDays[0].indexOf(':') === -1) {
      ret.days = parseInt(partsWithDays[0], 10);
      hoursMinutesSecondsIndex = 1;
    }
    const hoursMinutesSeconds = partsWithDays[hoursMinutesSecondsIndex].split(':');
    if (hoursMinutesSeconds.length === 3) {
      // it should always be 3 input will be either "days.hours:minutes:seconds" or "hours:minutes:seconds"
      ret.hours = parseInt(hoursMinutesSeconds[0], 10);
      ret.minutes = parseInt(hoursMinutesSeconds[1], 10);
      ret.seconds = parseInt(hoursMinutesSeconds[2], 10);
    }

    return ret;
  }

  constructor(days: number, hours: number, minutes: number, seconds: number = 0) {
    this.days = days;
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
  }

  public toString(): string {
    let ret = this.days > 0 ? `${this.days}.` : '';
    ret += `${this.hours}:${this.minutes}:${this.seconds}`;
    return ret;
  }
}

export class SystemVersion {
  public major: number;

  public minor: number;

  public build: number;

  public revision: number;

  public static parse(version: string): SystemVersion {
    if (StringHelper.isNullOrWhiteSpace(version)) {
      return new SystemVersion();
    }
    let ver = new SystemVersion();
    ver.major = 0;
    ver.minor = 0;
    ver.build = 0;
    ver.revision = 0;
    try {
      const parts = version.split('.');
      for (let i = 0; i < parts.length; i++) {
        switch (i) {
          case 0:
            ver.major = parseInt(parts[i], 10);
            break;
          case 1:
            ver.minor = parseInt(parts[i], 10);
            break;
          case 2:
            ver.build = parseInt(parts[i], 10);
            break;
          case 3:
            ver.revision = parseInt(parts[i], 10);
            break;
          default:
            break;
        }
      }
    }
    catch {
      ver = new SystemVersion();
    }
    return ver;
  }

  constructor(config?: any) {
    if (!config) {
      return;
    }
    for (const prop in config) {
      if (config.hasOwnProperty(prop)) {
        this[prop] = config[prop];
      }
    }
  }

  public isDefault() {
    return !this.major && !this.minor && !this.build && !this.revision;
  }

  public toString(includeRevision: boolean = false) {
    const numbers = [this.major, this.minor, this.build];
    if (includeRevision) {
      numbers.push(this.revision);
    }
    let ret = '';
    for (let i = 0; i < numbers.length; i++) {
      const number = numbers[i];
      if (number === undefined || number < 0) {
        return ret;
      }
      if (i > 0) {
        ret += '.';
      }
      ret += number;
    }
    return ret;
  }

  public compareTo(other: SystemVersion): number {
    if (other == null) {
      return 1;
    }
    if (this.major !== other.major) {
      return this.major > other.major ? 1 : -1;
    }

    if (this.minor !== other.minor) {
      return this.minor > other.minor ? 1 : -1;
    }

    if (this.build !== other.build) {
      return this.build > other.build ? 1 : -1;
    }

    if (this.revision !== other.revision) {
      return this.revision > other.revision ? 1 : -1;
    }

    return 0;
  }
}

export interface IFieldOrder {
  ascending?: boolean;
  field?: string;
}

export class TabItem {
  public id: string;

  public name: string;

  public filter: string;

  public icon: string;

  public hashtag: string;

  public hidden: boolean;

  public tabDisplay: string;

  constructor(
    id: string,
    name: string,
    filter: string,
    icon: string,
    hidden: boolean = false,
    tabDisplay: string = null
  ) {
    this.id = id;
    this.name = name;
    this.filter = filter;
    this.icon = icon;
    this.hidden = hidden;
    this.hashtag = StringHelper.toKebabCasing(name);
    this.tabDisplay = tabDisplay || name;
  }
}

export class TagNode {
  public id: number;

  public parent: TagNode = null;

  public name: string;

  public children: TagNode[] = null;

  public endpointsCount: number;

  public childrenLoaded: boolean = false; // set to true after children are loaded to prevent re-loading already loaded tags

  private editPermissions : TagEditPermissions = TagEditPermissions.None;

  constructor(id: number, name: string, hasChildren: boolean, parent: TagNode = null, endpointsCount: number = 0, editPermissions : TagEditPermissions = TagEditPermissions.None) {
    this.id = id;
    this.name = name;
    this.parent = parent;
    this.endpointsCount = endpointsCount;
    this.children = hasChildren ? [] : null;
    this.editPermissions = TypeHelper.isNull(editPermissions) ? TagEditPermissions.None : editPermissions;
  }

  public get hasChildren(): boolean { return !TypeHelper.isNull(this.children); }

  public get canEdit(): boolean { return EnumUtility.hasFlag(this.editPermissions, TagEditPermissions.Modify); }

  public get canAddRemoveTargets(): boolean { return EnumUtility.hasFlag(this.editPermissions, TagEditPermissions.AddRemoveTargets); }

  public get canAddRemoveTags(): boolean { return EnumUtility.hasFlag(this.editPermissions, TagEditPermissions.AddRemoveTags); }
}

export enum HealthCheckApiType {
  General,
  Compliance,
  Watcher
}

export class HealthCheckApiInfo {
  public name: string;

  public path: string;

  public type: HealthCheckApiType;

  constructor(name: string, path: string, type: HealthCheckApiType = null) {
    this.name = name;
    this.path = path;
    this.type = TypeHelper.isNull(type) ? HealthCheckApiType.General : type;
  }
}

export class ArrayNavigationHelper {
  public data: any[] = [];

  public currentItem: any = null;

  constructor(data: any[], currentItem: any) {
    this.data = ArrayHelper.isArraySet(data) ? data : [];
    this.currentItem = currentItem;
  }

  public get isValid(): boolean {
    return ArrayHelper.isArraySet(this.data) && !TypeHelper.isNull(this.currentItem);
  }

  public get currentPosition(): number {
    if (!this.isValid) {
      return 0;
    }
    return this.data.indexOf(this.currentItem) + 1;
  }

  public get canMovePrevious(): boolean {
    return this.isValid && this.currentPosition > 1;
  }

  public get canMoveNext(): boolean {
    return this.isValid && this.currentPosition < this.data.length;
  }

  public get navDisplay(): string {
    if (!this.isValid) {
      return '0 of 0';
    }
    return `${this.currentPosition} of ${this.data.length}`;
  }

  public movePrevious() {
    if (!this.canMovePrevious) {
      return;
    }
    const currentIndex = this.currentPosition - 1;
    this.currentItem = this.data[currentIndex - 1];
  }

  public moveNext() {
    if (!this.canMoveNext) {
      return;
    }
    const currentIndex = this.currentPosition - 1;
    this.currentItem = this.data[currentIndex + 1];
  }
}
