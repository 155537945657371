export class PermissionClaims {
  public readonly AccessSystemAdminSettings: string = 'Access_System_Admin_Settings';

  public readonly AddNewEndpoint: string = 'Add_New_Endpoint';

  public readonly AddTag: string = 'Add_Tag';

  public readonly AddUserIncidentNotificationREAD: string = 'AddUserIncidentNotificationREAD';

  public readonly AddUserIncidentNotificationWRITE: string = 'AddUserIncidentNotificationWRITE';

  public readonly AgentBulkAssignPolicy: string = 'Agent_Bulk_Assign_Policy';

  public readonly AgentBulkAssignTag: string = 'Agent_Bulk_Assign_Tag';

  public readonly AgentConfigurationDELETE: string = 'AgentConfigurationDELETE';

  public readonly AgentConfigurationREAD: string = 'AgentConfigurationREAD';

  public readonly AgentConfigurationWRITE: string = 'AgentConfigurationWRITE';

  public readonly AgentDetailsLIST: string = 'AgentDetailsLIST';

  public readonly AgentsAndEndpoints: string = 'Agents_And_Endpoints_';

  public readonly AssignPolicyREAD: string = 'AssignPolicyREAD';

  public readonly AssignPolicyWRITE: string = 'AssignPolicyWRITE';

  public readonly BulkDeleteScan: string = 'Bulk_Delete_Scan';

  public readonly BulkExportScan: string = 'Bulk_Export_Scan';

  public readonly ClientIncidentDefinitionREAD: string = 'ClientIncidentDefinitionREAD';

  public readonly ClientIncidentDefinitionWRITE: string = 'ClientIncidentDefinitionWRITE';

  public readonly ConfigureNewScan: string = 'Configure_New_Scan';

  public readonly CookiePolicyREAD: string = 'CookiePolicyREAD';

  public readonly CookiePolicyWRITE: string = 'CookiePolicyWRITE';

  public readonly CreatePlaybook: string = 'Create_Playbook';

  public readonly CustomScriptREAD: string = 'CustomScriptREAD';

  public readonly CustomScriptWRITE: string = 'CustomScriptWRITE';

  public readonly DashboardREAD: string = 'DashboardREAD';

  public readonly DefinitionPlaybookREAD: string = 'DefinitionPlaybookREAD';

  public readonly DefinitionPlaybookWRITE: string = 'DefinitionPlaybookWRITE';

  public readonly DeleteAgentPolicy: string = 'Delete_Agent_Policy';

  public readonly DeleteEndpoint: string = 'Delete_Endpoint';

  public readonly DeleteScan: string = 'Delete_Scan';

  public readonly DeleteScript: string = 'Delete_Script';

  public readonly DeleteTag: string = 'Delete_Tag';

  public readonly EditPlaybook: string = 'Edit_Playbook';

  public readonly EditScan: string = 'Edit_Scan';

  public readonly EditTag: string = 'Edit_Tag';

  public readonly EnableDisableScript: string = 'Enable_Disable_Script';

  public readonly ExportScript: string = 'Export_Script';

  public readonly ImportUserREAD: string = 'ImportUserREAD';

  public readonly ImportUserWRITE: string = 'ImportUserWRITE';

  public readonly IncidentArchiveREAD: string = 'Incident_ArchiveREAD';

  public readonly IncidentArchiveWRITE: string = 'Incident_ArchiveWRITE';

  public readonly IncidentDefinitionREAD: string = 'IncidentDefinitionREAD';

  public readonly IncidentDefinitionScriptREAD: string = 'IncidentDefinitionScriptREAD';

  public readonly IncidentDefinitionScriptWRITE: string = 'IncidentDefinitionScriptWRITE';

  public readonly IncidentDefinitionWRITE: string = 'IncidentDefinitionWRITE';

  public readonly IncidentDeploymentREAD: string = 'IncidentDeploymentREAD';

  public readonly IncidentDeploymentWRITE: string = 'IncidentDeploymentWRITE';

  public readonly IncidentREAD: string = 'IncidentREAD';

  public readonly IncidentWRITE: string = 'IncidentWRITE';

  public readonly KSQLREAD: string = 'KSQLREAD';

  public readonly KSQLWRITE: string = 'KSQLWRITE';

  public readonly ListAgentDetailsLIST: string = 'ListAgentDetailsLIST';

  public readonly ManagePersonalSettings: string = 'Manage_Personal_Settings';

  public readonly MenuAgentsREAD: string = 'Menu_AgentsREAD';

  public readonly MenuDashboardREAD: string = 'Menu_DashboardREAD';

  public readonly MenuIncidentsREAD: string = 'Menu_IncidentsREAD';

  public readonly MenuIncidentDefinitionsREAD: string = 'Menu_IncidentDefinitionsREAD';

  public readonly MenuQueriesREAD: string = 'Menu_QueriesREAD';

  public readonly OrganizationLIST: string = 'OrganizationLIST';

  public readonly OrganizationREAD: string = 'OrganizationREAD';

  public readonly OrganizationWRITE: string = 'OrganizationWRITE';

  public readonly PlaybookExecutor: string = 'Playbook_Executor';

  public readonly PolicyConfigREAD: string = 'PolicyConfigREAD';

  public readonly PolicyConfigWRITE: string = 'PolicyConfigWRITE';

  public readonly QueriesREAD: string = 'QueriesREAD';

  public readonly QueryCategoryREAD: string = 'QueryCategoryREAD';

  public readonly QueryCategoryWRITE: string = 'QueryCategoryWRITE';

  public readonly QueryLIST: string = 'QueryLIST';

  public readonly QueryREAD: string = 'QueryREAD';

  public readonly QueryWRITE: string = 'QueryWRITE';

  public readonly RemoveAgentREAD: string = 'RemoveAgentREAD';

  public readonly RemoveAgentWRITE: string = 'RemoveAgentWRITE';

  public readonly ResetPwdEmailREAD: string = 'ResetPwdEmailREAD';

  public readonly ResetPwdEmailWRITE: string = 'ResetPwdEmailWRITE';

  public readonly RoleDELETE: string = 'RoleDELETE';

  public readonly RoleREAD: string = 'RoleREAD';

  public readonly RoleWRITE: string = 'RoleWRITE';

  public readonly RunScan: string = 'Run_Scan';

  public readonly ScanDashboard: string = 'Scan_Dashboard';

  public readonly SidLIST: string = 'SidLIST';

  public readonly SysRoleAdministrator: string = 'sysrole_Administrator';

  public readonly UpdateDefinitionStatusREAD: string = 'UpdateDefinitionStatusREAD';

  public readonly UpdateDefinitionStatusWRITE: string = 'UpdateDefinitionStatusWRITE';

  public readonly UserDELETE: string = 'UserDELETE';

  public readonly UserLIST: string = 'UserLIST';

  public readonly UserProfileREAD: string = 'UserProfileREAD';

  public readonly UserProfileWRITE: string = 'UserProfileWRITE';

  public readonly UserREAD: string = 'UserREAD';

  public readonly UserWRITE: string = 'UserWRITE';

  public readonly ViewPlaybooksList: string = 'View_Playbooks_List';

  public readonly ViewScanList: string = 'View_Scan_List';

  public readonly ViewTagList: string = 'View_Tag_List';

  public readonly Admin: string = 'Admin';

  // public readonly AgentsandEndpoints: string = 'Agents-and-Endpoints';

  public readonly IdentityRequests: string = 'Identity-Requests';

  public readonly IdentityResults: string = 'Identity-Results';

  public readonly Installation: string = 'Installation';

  public readonly MenuConfigurationREAD: string = 'Menu_ConfigurationREAD';

  public readonly NewPlaybook: string = 'New-Playbook';

  public readonly PasswordPolicyAdmin: string = 'PasswordPolicyAdmin';

  public readonly Playbooks: string = 'Playbooks';

  public readonly Policies: string = 'Policies';

  public readonly Results: string = 'Results';

  public readonly ScanResultsRead: string = 'ScanResultsREAD';

  public readonly ScanResultsWrite: string = 'ScanResultsWRITE';

  public readonly Scans: string = 'Scans';

  public readonly ScriptRepository: string = 'Script-Repository';

  public readonly TagManagement: string = 'Tag-Management';

  public readonly ComplianceDashboard: string = 'View_Compliance_Dashboard';

  public readonly ComplianceAllRequests: string = 'View_All_Request';

  public readonly ComplianceNewSubjectRequest: string = 'Create_New_Subject_Request';

  public readonly ComplianceEditSubjectRequest: string = 'Edit_Subject_Request';

  public readonly ComplianceStartMatching: string = 'Start_Matching';

  public readonly ComplianceDeleteSubjectRequest: string = 'Delete_Subject_Request';

  public readonly ComplianceDuplicateRequest: string = 'Duplicate_Request';

  public readonly ComplianceViewSubjectResults: string = 'View_Subject_Results';

  public readonly ComplianceViewRequestDetails: string = 'View_Request_Details';

  public readonly ComplianceFileContentReviewChangeMatchStatus: string = 'Change_Match_Status';

  public readonly ComplianceExportReport : string = 'Export_Report';

  public readonly ComplianceCreateReport: string = 'Create_Report';

  public readonly ComplianceResultsComments: string = 'Results_Comments';

  public readonly CompliancCompleteSubjectRequest: string = 'Complete_Subject_Request';

  public readonly ComplianceExportResults: string = 'Export_Results';

  public readonly ComplianceReviewFileContent: string = 'Review_File_Content';

  public readonly ComplianceViewAllReports: string = 'View_All_Reports';

  public readonly Remediation: string = 'Remediation';

  public readonly ActivityWatcher: string = 'Menu_Watcher';

  public readonly ComplianceErasureChecklist: string = 'Erasure_Checklist_READ';

  public readonly ComplianceErasureEdit: string = 'Erasure_Checklist_WRITE';

  public readonly CreateCustomReport: string = 'Decr_Create';

  public readonly AccessRestApi: string = 'Rest_API'

  // This claim was commented for SCM-927. If this is added back search for all commented uses of ComplianceViewReport and fix those areas.
  // public readonly ComplianceViewReport: string = 'View_Report';
}

const claims = new PermissionClaims();

export default claims;
